import { keyBy } from 'lodash-es'

interface FetchOptions {
  signal?: AbortSignal
}

type DimType = Api.Dim.Type

export async function withDimInfo(
  fetcher: Promise<Api.TableResponse<any>>,
  dims: (DimType | (string | number)[])[] = [],
  options: FetchOptions = {},
) {
  const tableRes = await fetcher

  await Promise.all(dims.map((dim) => {
    const dimType = typeof dim === 'string' ? dim : dim[0] as DimType

    const config = {
      product: {
        idKey: 'productId',
        dataKey: 'productData',
        fetcher: fetchProductInfo,
      },
      shop: {
        idKey: 'shopId',
        dataKey: 'shopData',
        fetcher: fetchShopInfo,
      },
      blogger: {
        idKey: 'bloggerId',
        dataKey: 'bloggerData',
        fetcher: fetchBloggerInfo,
      },
      video: {
        idKey: 'awemeId',
        dataKey: 'videoData',
        fetcher: fetchVideosInfo,
      },
      live: {
        idKey: 'roomId',
        dataKey: 'liveData',
        fetcher: fetchLiveInfo,
      },
    }
    const { fetcher, idKey, dataKey } = config[dimType]
    return fetcher(tableRes.data.map(item => item[idKey]).join(','), options).then((res) => {
      tableRes.data.forEach((row) => {
        row[dataKey] = res[row[idKey]]
      })
    })
  }))

  return tableRes
}

// 获取点查的接口

export async function fetchProductInfo(productIds: string, opt?: FetchOptions) {
  if (!productIds)
    return {}
  const res = await useNuxtApp().$request<{ data: Api.Dim.Product.Item[] }>('/dim/info/products', {
    query: {
      productIds,
    },
    ...opt,
  })
  return keyBy(res.data, 'productId')
}
export async function fetchShopInfo(shopIds: string, opt?: FetchOptions) {
  if (!shopIds)
    return {}
  const res = await useNuxtApp().$request<{ data: Api.Dim.Shop.Item[] }>('/dim/info/shops', {
    query: {
      shopIds,
    },
    ...opt,
  })
  return keyBy(res.data, 'shopId')
}

export async function fetchBloggerInfo(bloggerIds: string, opt?: FetchOptions) {
  if (!bloggerIds)
    return {}
  const res = await useNuxtApp().$request<{ data: Api.Dim.Blogger.Item[] }>('/dim/info/bloggers', {
    query: {
      bloggerIds,
    },
    ...opt,
  })
  return keyBy(res.data, 'bloggerId')
}

export async function fetchVideosInfo(awemeIds: string, opt?: FetchOptions) {
  if (!awemeIds)
    return {}
  const res = await useNuxtApp().$request<{ data: Api.Dim.Video.Item[] }>('/dim/info/videos', {
    query: {
      awemeIds,
    },
    ...opt,
  })
  return keyBy(res.data, 'awemeId')
}

export async function fetchLiveInfo(roomIds: string, opt?: FetchOptions) {
  if (!roomIds)
    return {}
  const res = await useNuxtApp().$request<{ data: Api.Dim.Live.Item[] }>('/dim/info/lives', {
    query: {
      roomIds,
    },
    ...opt,
  })
  return keyBy(res.data, 'roomId')
}

/** 收藏dim */
export async function fetchCollectionInfo(resourceIds: string, favoritesType = 2, opt?: FetchOptions) {
  const user = useUser()
  if (!resourceIds || !user.value?.info?.id)
    return {}
  try {
    const res = await useNuxtApp().$request<{ data: Api.Favourite.Resource[] }>('/favorites/resource', {
      baseURL: useRuntimeConfig().public.apiV2,
      query: {
        resourceIds,
        favoritesType,
      },
      ...opt,
    })
    return keyBy(res.data, 'resourceId')
  }
  catch (error) {
    console.error('fetchCollectionInfo failed:', error)
    return {} // 返回空对象，确保不会影响其他数据
  }
}
